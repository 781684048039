/* eslint-disable no-console */

import { addPoiGroup, addTreeGroup } from './links';

const $ = window.jQuery;

export function watchPanoForm( element ) {
	const linkG = element.svg.select( 'g.wr-links' );

	let currentG = null;
	let currentUseTag = null;

	const inAdmin = $( 'body' ).hasClass( 'wp-admin' );
	const isTree = $( 'body' ).hasClass( 'post-type-tree_panorama' );
	const isPoi = $( 'body' ).hasClass( 'post-type-poi_panorama' );
	const isPano = isTree || isPoi;

	const isNewTree = isTree && readTreeId() === 0;
	const isOldTree = isTree && readTreeId() !== 0;
	const isNewPoi = isPoi && ! hasPoiGroup( readPostId() );
	const isOldPoi = isPoi && hasPoiGroup( readPostId() );

	if ( inAdmin && isPano ) {
		watchForm();
	}

	function watchForm() {
		console.log( 'watchForm' );

		if ( isNewTree ) {
			// console.log( 'NEW TREE' );
			// console.log( 'add new tree group' );
			addTreeGroup( element, readTreeId(), readCoords(), readDirection(), readActiveState(), readUseType(), readName() );
		}

		if ( isOldTree ) {
			// console.log( 'OLD TREE' );
		}

		if ( isNewPoi ) {
			// console.log( 'NEW POI' );
			// console.log( 'add new poi group' );
			addPoiGroup( element, readTitle(), readCoords(), readDirection(), readSymId(), readActiveState(), readPostId() );
		}

		if ( isOldPoi ) {
			// console.log( 'OLD POI' );
		}

		if ( isTree ) {
			const treeId = readTreeId();
			currentG = linkG.select( `g#rb-${ treeId }` );
		}

		if ( isPoi ) {
			const postId = readPostId();
			currentG = linkG.select( `g#poi-${ postId }` );
		}

		currentG.attr( 'id', 'curG' );
		currentG.classed( 'curG', true );
		currentG.raise();

		currentUseTag = currentG.select( 'use' );
		currentUseTag.classed( 'current', true );

		$( '#wrp_geo_lng' ).on( 'input change', () => updateCoords() );
		$( '#wrp_geo_lat' ).on( 'input change', () => updateCoords() );
		$( '#wrp_use_type' ).on( 'change', () => updateUseType() );
		$( 'input[type="radio"][name="wrp_type"]' ).on( 'change', () => updateName() );
		$( '#wrp_direction' ).on( 'change', () => updateSymbol() );
		$( '#wrp_decoration' ).on( 'change', () => updateSymbol() );
		$( '#wrp_in_tour' ).on( 'change', () => updateActiveState() );
		$( '#wrp_tree_number' ).on( 'change', () => updateTreeNumber() );
		$( '#title' ).on( 'input change', () => updateName() );

		$( document ).on( 'click', '#digipano-data-import-yes', () => {
			setTimeout( function() {
				// // console.log( 'DATA IMPORT BUTTON CLICKED' );
				onDataImport();
			}, 250 );
		} );
	}

	function readTreeId() {
		const idVal = $( '#wrp_tree_number' ).val();
		if ( idVal === '' ) {
			return 0;
		}
		return idVal;
	}

	function readCoords() {
		const lng = Number.parseFloat( $( '#wrp_geo_lng' ).val() ) || 0;
		const lat = Number.parseFloat( $( '#wrp_geo_lat' ).val() ) || 0;
		// console.log( 'Read input:' + [ lng, lat ] );
		return [ lng, lat ];
	}

	function readTitle() {
		return $( '#title' ).val() || 'Mir fehlt noch ein Titel';
	}

	function readDirection() {
		return $( '#wrp_direction' ).val();
	}

	function readDecoration() {
		return $( '#wrp_decoration' ).val();
	}

	function readSymId() {
		const direction = readDirection();
		const decoration = readDecoration();

		if ( decoration === 'none' ) {
			return direction;
		}
		return `${ direction }-${ decoration }`;
	}

	function readActiveState() {
		const checkbox = $( '#wrp_in_tour' );
		return checkbox.prop( 'checked' );
	}

	function readUseType() {
		return $( '#wrp_use_type' ).val();
	}

	function readPostId() {
		return $( '#post_ID' ).val() || 0;
	}

	function readType() {
		return $( 'input[name="wrp_type"]:checked' ).val();
	}

	function readName() {
		if ( isPoi ) {
			return $( 'title' ).val();
		}
		const treeId = readTreeId();
		const type = readType();
		if ( type === 'tree' ) {
			return `Ruhebaum ${ treeId }`;
		}
		if ( type === 'stone' ) {
			return `Ruhefindling ${ treeId }`;
		}
	}

	function hasPoiGroup( postId ) {
		// console.log( 'hasPoiGroup' );
		// console.log( 'postId', postId );
		// console.log( 'linkG', linkG );
		const poiG = linkG.select( `g#poi-${ postId }` );
		// console.log( 'poiG', poiG );
		return poiG.size() !== 0;
	}

	function updateCoords() {
		// console.log( 'updateCoords' );
		const coords = readCoords();
		// console.log( element.projection );
		const [ x, y ] = element.projection( coords );
		console.log( 'projected coords', [ x, y ] );
		currentG.attr( 'transform', `translate(${ x }, ${ y })` );
		currentG.raise();
	}

	function updateSymbol() {
		updateDirection();
		// console.log( 'updateSymbol' );
		const symId = readSymId();

		currentUseTag.attr( 'href', `#${ symId }` );
	}

	function updateUseType() {
		// console.log( 'updateUseType ' );
		const useTypeVal = readUseType();

		if ( useTypeVal === 'fam' ) {
			currentUseTag.classed( 'gem', false ).classed( 'fam', true );
		}

		if ( useTypeVal === 'gem' ) {
			currentUseTag.classed( 'fam', false ).classed( 'gem', true );
		}
	}

	function updateName() {
		// console.log( 'updateName' );

		let name = '';

		if ( isTree ) {
			const type = readType();
			const treeId = $( '#wrp_tree_number' ).val();

			if ( type === 'tree' ) {
				name = `Ruhebaum ${ treeId }`;
			}

			if ( type === 'stone' ) {
				name = `Ruhefindling ${ treeId }`;
			}
		}
		if ( isPoi ) {
			name = readTitle();
		}
		currentUseTag.attr( 'data-name', name );
	}

	function updateActiveState() {
		// console.log( 'updateActiveState' );

		const checkbox = $( '#wrp_in_tour' );
		const isActive = checkbox.prop( 'checked' );
		currentUseTag
			.classed( 'deactivated', ! isActive )
			.classed( 'activated', isActive );
	}

	function updateTreeNumber() {
		// console.log( updateTreeNumber );
		updateName();
	}

	/*function updateCurrent() {
		// console.log( 'updateCurrent' );
		const linkG = element.svg.select( 'g.wr-links' );

		// Remove CSS-Class current
		const formerCurrent = linkG.select( 'use.current' );
		formerCurrent.classed( 'current', false );

		// Set current CSS-Class
		const currentG = getCurrentGroup();
		const newCurrent = currentG.select( 'use' );
		newCurrent.classed( 'current', true );
	}*/

	function onDataImport( ) {
		$( '#wrp_geo_lat' ).trigger( 'input' );
	}

	function updateDirection() {
		// console.log( 'update Direction' );
		const newDirection = readDirection();

		currentUseTag.attr( 'href', `#${ newDirection }` );
		currentUseTag.attr( 'data-direction', `${ newDirection }` );

		const allDirections = [ 'north', 'north-east', 'east', 'south-east', 'south', 'south-west', 'west', 'north-west' ];

		allDirections.forEach( ( dir ) => {
			currentUseTag.classed( dir, false );
		} );

		currentUseTag.classed( newDirection, true );
	}
}
