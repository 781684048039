/* eslint-disable no-console */
import * as d3 from 'd3';
import * as turf from '@turf/turf';

/**
 * Draws a property on a D3 SVG element using GeoJSON data with a frosted glass effect and a strong shadow.
 *
 * @param {Object} geojson - The GeoJSON data representing the property.
 * @param {Object} element - The DOM element containing the SVG and projection.
 */
export function drawProperty( geojson, element ) {
	//console.log( 'drawProperty called' );

	// Extract the projection and create a geo path generator
	const projection = element.projection;
	const geoGenerator = d3.geoPath().projection( projection );

	// Ensure the coordinates are wound correctly (clockwise or counterclockwise)
	geojson = turf.rewind( geojson, { reverse: true } );

	// Create a group for the property paths and bind the GeoJSON features data
	d3
		.select( element )
		.select( 'g.wr-property' )
		.selectAll( 'path' )
		.data( geojson.features )
		.enter() // Enter selection to handle new data
		.append( 'path' )
		.attr( 'd', geoGenerator );
}
