export const config = [];

config.stk = {
	width: 800,
	height: 600,
	margin: {
		top: 70,
		right: 30,
		bottom: 20,
		left: 30,
	},
	fontFactor: 1.5,
};

config.drz = {
	width: 550,
	height: 800,
	margin: {
		top: 0,
		right: 50,
		bottom: 0,
		left: 149,
	},
	fontFactor: 1.2,
};

config.amp = {
	width: 800,
	height: 600,
	margin: {
		top: 20,
		right: 50,
		bottom: 20,
		left: 20,
	},
	fontFactor: 1,
};
