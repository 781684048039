'use strict';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import * as d3 from 'd3';
import { drawTrees } from './trees';
import { drawSections } from './sections';
import { drawProperty } from './property';
import { drawTraffic } from './traffic';
import { config } from './config';
import { onInventoryLoaded } from './inventory';
import { createPanoLinks } from './links';
import { watchPanoForm } from './watchPanoForm';
import { addResizeHandler, updateMapClass } from './tooltip-fontsize';
import { addInvertOnClickListener } from './invertCoords';
import { addZoom } from './zoom';
import { createTooltip } from './tooltip';
//import { addMapMouseLeaveListerner } from './tooltip';
// import { createSymbolForm } from './createSymbolForm';

const geojsonFolder = window.wrgObject.geojsonFolder;

export async function map() {
	const maps = Array.from( document.getElementsByClassName( 'waldruh-map' ) );

	if ( maps.length ) {
		for ( const element of maps ) {
			await prepareElement( element );
			drawMap( element );
		}
	}

	updateMapClass();
	addResizeHandler();
	// addMapMouseLeaveListerner();
}

async function prepareElement( element ) {
	addSokProperty( element );
	const url = `https:${ geojsonFolder }/${ element.sok }_property.geojson`;
	const geojson = await loadGeojson( url );
	initializeElement( geojson, element );
	element.getLayer = getLayerMethod;
}

function addSokProperty( element ) {
	element.sok = element.getAttribute( 'data-sok' ) || window.wrgObject.sok;
	console.log( 'data-sok', element.getAttribute( 'data-sok' ) );
	if ( ! element.getAttribute( 'data-sok' ) ) {
		element.setAttribute( 'data-sok', window.wrgObject.sok );
	}
}

async function loadGeojson( url ) {
	try {
		return await d3.json( url );
	} catch ( error ) {
		console.error( `Fehler beim Laden von GeoJSON:\n${ url }\n\n${ error }` );
	}
}

function initializeElement( geojson, element ) {
	addProjectionProperty( geojson, element );
	createSVG( element );
	appendLayers( element.svg );
	addZoom( element );
	createTooltip( element );
}

function addProjectionProperty( geojson, element ) {
	const { width, height, margin } = config[ element.sok ];
	const mLng = d3.geoCentroid( geojson )[ 0 ];
	console.log( mLng );
	element.projection = d3
		.geoTransverseMercator()
		.rotate( [ -mLng, 0 ] )
		.fitExtent(
			[
				[ margin.left, margin.top ],
				[ width - margin.right, height - margin.bottom ],
			],
			geojson
		);
}

function createSVG( element ) {
	const { width, height, margin } = config[ element.sok ];

	const totalWidth = width + margin.left + margin.right;
	const totalHeight = height + margin.top + margin.bottom;

	element.svg = d3.select( element )
		.append( 'svg' )
		.attr( 'viewBox', [ -margin.left, -margin.top, totalWidth, totalHeight ] )
		.attr( 'preserveAspectRatio', 'xMidYMid meet' );
	element.defs = element.svg.insert( 'defs', ':first-child' );
}

function appendLayers( svg ) {
	const main = svg.append( 'g' ).classed( `main`, true );
	const layers = [ 'property', 'sections', 'inventory', 'traffic', 'symbols', 'links' ];
	layers.forEach( ( layer ) => main.append( 'g' ).classed( `wr-${ layer }`, true ) );
}

function getLayerMethod( name ) {
	return d3.select( this.svg ).select( `wr-${ name }` );
}

async function drawMap( element ) {
	const sok = element.sok;

	function load( layer ) {
		const url = `https:${ geojsonFolder }/${ sok }_${ layer }.geojson`;
		return new Promise( ( resolve, reject ) => {
			d3.json( url )
				.then( ( geojson ) => {
					resolve( { geojson, layer } );
				} )
				.catch( ( error ) => {
					console.error( `Fehler. Konnte die folgende Datei nicht verarbeiten:\n${ url }\n\n${ error }` );
					reject( error );
				} );
		} );
	}

	// Array of layer load promises
	const layers = [
		load( 'property' ).then( ( { geojson } ) => drawProperty( geojson, element, sok ) ),
		load( 'trees' ).then( ( { geojson } ) => drawTrees( geojson, element, sok ) ),
		load( 'sections' ).then( ( { geojson } ) => drawSections( geojson, element, sok ) ),
		load( 'inventory' ).then( ( { geojson } ) => onInventoryLoaded( geojson, element, sok ) ),
		load( 'traffic' ).then( ( { geojson } ) => drawTraffic( geojson, element, sok ) ),
	];

	// Wait for all layers to be loaded and callbacks to be processed
	await Promise.all( layers );

	await createPanoLinks( element );

	// console.log('projection', element.projection);
	watchPanoForm( element );

	// Event erzeugen
	const event = new CustomEvent( 'wrg-map-completely-drawn', {
		detail: {
			message: 'wrg map has been drawn completely',
			time: new Date(),
			element,
		},
	} );

	// addTooltip( element );
	addInvertOnClickListener( element );
	// Event auslösen
	document.dispatchEvent( event );
}

