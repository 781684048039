/* eslint-disable no-console */
import polylabel from 'polylabel';

function calculatePolygonCenterAndDistance( polygon ) {
	try {
		//console.log( 'Calculating polylabel for polygon:', polygon );
		const result = polylabel( polygon, 0.1, false );

		//console.log( 'result', result );
		const point = [ result[ 0 ], result[ 1 ] ];
		//console.log( 'point', point );

		return {
			center: point,
			distance: result.distance,
		};
	} catch ( error ) {
		console.error( 'Error calculating polygon center and distance:', error );
		return {
			center: null,
			distance: null,
		};
	}
}

export function calculateFeatureCenterAndDistance( feature, projection ) {
	const coords = feature.geometry.coordinates[ 0 ];
	//console.log( element.sok );
	//console.log( 'Original Coordinates:', coords );

	// Projizieren der Polygonkoordinaten
	const projectedCoords = coords.map( ( point ) => projection( point ) );
	//console.log( 'Projected Coordinates:', projectedCoords );

	// Formatieren der projizierten Koordinaten für Polylabel
	const formattedCoords = [ projectedCoords.map( ( point ) => [ point[ 0 ], point[ 1 ] ] ) ];
	//console.log( 'Formatted Coordinates for Polylabel:', formattedCoords );

	return calculatePolygonCenterAndDistance( formattedCoords );
}
