/* eslint-disable no-console */
import * as d3 from 'd3';
import * as turf from '@turf/turf';
import { importDefs } from './svg-import';
import { addTooltipListener } from './tooltip';
import { drawText } from './textLabels';
import { drawWaveDecoration } from './stk-wave-deco';

const wrgObject = window.wrgObject;

// Function to calculate a robust midpoint
function getRobustMidpoint( geojson, element, id ) {
	// Filter the feature with the given ID
	const selectedFeature = geojson.features.find( ( feature ) => feature.properties.id === id );

	// Check if a feature is found
	if ( ! selectedFeature ) {
		console.log( 'Feature with ID not found:', id );
		return null;
	}

	// Calculate a point on the surface of the selected feature
	const midpoint = turf.pointOnFeature( selectedFeature );

	// console.log( 'id:', id );
	// console.log( 'Feature:', selectedFeature );
	// console.log( 'Calculated midpoint:', midpoint.geometry.coordinates );

	return midpoint.geometry.coordinates;
}

function addSymbol( geojson, element, id, symbolId, width, height, dx = 0, dy = 0, tooltipText = '' ) {
	const symG = d3.select( element ).select( 'svg' ).select( 'g.wr-symbols' );
	const projection = element.projection;

	const [ x, y ] = projection( getRobustMidpoint( geojson, element, id ) );

	// Debug output for coordinates and projection
	//console.log( 'Geographic coordinates:', coord );
	//console.log( 'Projected coordinates:', { x, y } );

	const currentSymG = symG.append( 'g' ).attr( 'transform', `translate(${ x + dx - ( width / 2 ) }, ${ y + dy - ( height / 2 ) })` );

	currentSymG
		.append( 'circle' )
		.attr( 'fill', 'transparent' )
		.attr( 'r', '1' )
		.attr( 'cx', `${ width / 2 }` );

	currentSymG
		.attr( 'data-tooltip', tooltipText )
		.append( 'use' )
		.attr( 'xlink:href', `#${ symbolId }` )
		.attr( 'width', width )
		.attr( 'height', height );
}

export async function drawSymbols( geojson, element ) {
	geojson.features.forEach( ( feature ) => {
		getRobustMidpoint( geojson, element, feature.properties.id );
	} );

	const sok = element.sok;
	// console.log( 'sok', sok );

	if ( sok === 'drz' ) {
		await importDefs( `${ wrgObject.pluginFolder }/map/svg/map-symbols/drz/schilf-pattern.svg` );
		await importDefs( `${ wrgObject.pluginFolder }/map/svg/map-symbols/drz/drz-symbols.svg` );
	}

	if ( sok === 'stk' ) {
		await importDefs( `${ wrgObject.pluginFolder }/map/svg/map-symbols/stk/lake-pattern.svg` );
	}

	await importDefs( `${ wrgObject.pluginFolder }/map/svg/map-symbols/symbols.svg` );

	if ( sok === 'drz' ) {
		/* eslint-disable no-multi-spaces */
		addSymbol( geojson, element, 19, 'drz-group-of-trees', 50,    50,     0,  0, 'Baumgruppe' );
		addSymbol( geojson, element, 15, 'drz-biotop',         10,    10,     0,  0, 'Biotop' );
		addSymbol( geojson, element, 11, 'drz-p1',             20,    20,     0, 10, 'Parkplatz 1' );
		addSymbol( geojson, element, 12, 'drz-p2',             20,    20,     5, 10, 'Parkplatz 2' );
		addSymbol( geojson, element,  9, 'drz-ap',             12.02, 15.285, 2,  0, 'Andachtsplatz' );
		addSymbol( geojson, element, 16, 'drz-ap',             12.02, 15.285, 0,  0, 'Andachtsplatz' );
		// addSymbol( geojson, element, 21, 'drz-ap',          12.02, 15.285, 0,  0, 'Andachtsplatz' );
		addSymbol( geojson, element, 10, 'drz-pavilion',       30,    30,     5, -5, 'Pavillon' );
		addSymbol( geojson, element,  8, 'drz-fisher',         30,    30,     0,  0, 'Fischerhütte' );

		drawText(  geojson, element, 13, 'Schilfzone' );
		/* eslint-enable no-multi-spaces */
	}

	if ( sok === 'stk' ) {
		/* eslint-disable no-multi-spaces */
		addSymbol( geojson, element, 21, 'ap-1b',  30, 30,   0, -10, 'Andachtsplatz 1' );
		addSymbol( geojson, element, 20, 'ap-2',   30, 30,   2,  -2, 'Andachtsplatz 2' );
		addSymbol( geojson, element, 23, 'chapel', 30, 30,  15, -13, 'Kapelle' );
		addSymbol( geojson, element, 1,  'p1',     30, 30, -25,  10, 'Parkplatz 1' );
		addSymbol( geojson, element, 2,  'p2',     30, 30, -18,  15, 'Parkplatz 2' );
		addSymbol( geojson, element, 15, 'p3',     30, 30, -15,  15, 'Parkplatz 3' );
		addSymbol( geojson, element, 3,  'p4',     30, 30, -15, -10, 'Parkplatz 4' );
		/* eslint-enable no-multi-spaces */
		drawWaveDecoration( element );
	}

	if ( sok === 'amp' ) {
		/* eslint-disable no-multi-spaces */
		addSymbol( geojson, element, 1, 'p',      30, 30, 0, -15, 'Parkplatz' );
		addSymbol( geojson, element, 4, 'p-dis',  30, 30, 2,  10, 'Behinderten-Parkplätze' );
		addSymbol( geojson, element, 5, 'chapel', 30, 30, 0, -10, 'Kapelle' );
		addSymbol( geojson, element, 6, 'ap',     30, 30, 0,   0, 'Andachtsplatz' );
		/* eslint-enable no-multi-spaces */

		d3.select( element ).select( 'svg' ).classed( 'dark', true );

		/*
		 We want the surface element of the parking spaces to be below the road.
		 That is why we

		 We achieve this by creating a new group 'wr-parking' before the traffic
		 group and moving the parking spaces from the inventory to this level.
		*/
		const svg = d3.select( element ).select( 'svg' );
		const pG = svg.select( 'g.main' ).insert( 'g', 'g.wr-property' ).attr( 'class', 'parking' );

		const parkplaetze = svg.selectAll( 'g.wr-inventory g.Parkplatz' );

		parkplaetze.each( function() {
			const node = d3.select( this ).node().cloneNode( true );
			pG.node().appendChild( node );
		} );
	}
	/* eslint-enable no-multi-spaces */
	addTooltipListener( element );
}

