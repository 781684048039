'use strict';

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import * as d3 from 'd3';
import * as turf from '@turf/turf';

export async function drawInventory( geojson, element ) {
	const projection = element.projection;
	geojson = turf.rewind( geojson, { reverse: true } );

	const geoGenerator = d3.geoPath().projection( projection );

	const inventory = d3.select( element )
		.select( 'svg' )
		.select( 'g.wr-inventory' );

	inventory.selectAll( 'g' )
		.data( geojson.features )
		.join( 'g' )
		.attr( 'class', ( d ) => `${ d.properties.label } inventory-${ d.properties.id }` )
		.append( 'path' )
		.attr( 'd', geoGenerator );
}
