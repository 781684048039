'use strict';

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import { drawSymbols } from './symbols';
import { drawInventory } from './inventoryGroup';

export async function onInventoryLoaded( geojson, element ) {
	await drawInventory( geojson, element );
	drawSymbols( geojson, element );
}
