/* eslint-disable no-console */
import * as d3 from 'd3';
import { config } from './config';

export function addZoom( element ) {
	const { width, height, margin } = config[ element.sok ];

	const svg = d3.select( element ).select( 'svg' );
	const mainG = svg.select( 'g.main' );

	const zoom = d3.zoom()
		.scaleExtent( [ 1, 10 ] ) // Zoom-Stufen begrenzen
		.translateExtent( [ [ -margin.left, -margin.top ], [ width + margin.right, height + margin.bottom ] ] ) // Panning auf initialen Bereich begrenzen
		.on( 'zoom', function( event ) {
			mainG.attr( 'transform', event.transform );
			// Tooltip ausblenden
			d3.selectAll( '.wrg-tooltip' ).style( 'opacity', 0 );
		} );

	element.zoom = zoom;
	// Zoom auf das SVG-Element anwenden
	svg.call( zoom );
}
