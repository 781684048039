/* eslint-disable no-console */
const wrpObject = window.wrpObject;
const $ = window.jQuery;

export function getCoordsPromise( type ) {
	const url = wrpObject.ajaxURL;
	const reqData = {};
	reqData.nonce = wrpObject.nonce;

	if ( type === 'tree' ) {
		reqData.action = 'get-tree-coords';
	}

	if ( type === 'poi' ) {
		reqData.action = 'get-poi-coords';
	}

	return new Promise( ( resolve, reject ) => {
		$.ajax( {
			url,
			type: 'post',
			data: reqData,
			success( response ) {
				response = JSON.parse( response );
				resolve( response );
			},
			error( error ) {
				reject( error );
			},
		} );
	} );
}
