'use strict';

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import * as d3 from 'd3';
import { calculateFeatureCenterAndDistance } from './polylabelHelper';

function filterById( geojson, id ) {
	// Filter the feature with the given ID
	const feature = geojson.features.find( ( feat ) => feat.properties.id === id );

	// Check if a feature is found
	if ( ! feature ) {
		console.error( 'Feature with ID not found:', id );
		return null;
	}

	return feature;
}

export function drawText( geojson, element, id, text, dx = 0, dy = 0 ) {
	const feature = filterById( geojson, id );
	const projection = element.projection;

	const resultObject = calculateFeatureCenterAndDistance( feature, projection );

	const center = resultObject.center;
	const x = center[ 0 ] + dx;
	const y = center[ 1 ] + dy;

	const labelG = d3.select( element ).select( 'svg' ).select( 'g.wr-inventory' ).select( `g.inventory-${ id }` );

	labelG.append( 'g' )
		.attr( 'transform', `translate( ${ x }, ${ y } )` )
		.append( 'text' )
		.text( text );
}
