/* eslint-disable no-console */
import * as d3 from 'd3';

export function addInvertOnClickListener( element ) {
	// Ensure element is a D3 selection
	const d3Element = d3.select( element );
	if ( d3Element.attr( 'data-invert' ) === 'on' ) {
		let coordsOutput = d3.select( '#coords-output' );

		// If the coordsOutput div does not exist, create it
		if ( coordsOutput.empty() ) {
			coordsOutput = d3.select( 'body' ).append( 'div' )
				.attr( 'id', 'coords-output' )
				.attr( 'opacity', 0 )
				.text( '' );
		}

		d3Element.on( 'click', function( event ) {
			const [ x, y ] = d3.pointer( event );
			// Ensure projection is available on the element
			const projection = d3Element.property( 'projection' );
			if ( projection ) {
				const geoCoordinates = projection.invert( [ x, y ] );
				coordsOutput
					.text( `Koordinaten: ${ geoCoordinates[ 0 ].toFixed( 6 ) }, ${ geoCoordinates[ 1 ].toFixed( 6 ) }` )
					.attr( 'opacity', 0.7 );
				console.log( 'geoCoordinates', geoCoordinates );
			} else {
				console.error( 'Projection not found on element' );
			}
		} );
	}
}
