/* eslint-disable no-console */
import * as d3 from 'd3';
import { addTraficTooltipListener } from './tooltip-traffic';

/**
 * Draws the traffic layer with d3.
 *
 * @param {Object} geojson - The GeoJSON data representing the property.
 * @param {Object} element - The DOM element containing the SVG and projection.
 */
export function drawTraffic( geojson, element ) {
	// Define street types
	const streetTypes = [ 'STR', 'FST' ];

	// Sort features: streets last, paths first
	const sortedFeatures = geojson.features.sort( ( a, b ) => {
		const isStreetA = streetTypes.includes( a.properties.type );
		const isStreetB = streetTypes.includes( b.properties.type );
		return isStreetA - isStreetB; // Sort streets after paths
	} );

	// Extract the projection and create a geo path generator
	const projection = element.projection;
	const geoGenerator = d3.geoPath().projection( projection );

	// Create and bind the sorted GeoJSON data
	d3
		.select( element )
		.select( 'svg' )
		.select( 'g.wr-traffic' )
		.selectAll( 'path' )
		.data( sortedFeatures )
		.enter()
		.append( 'path' )
		.attr( 'd', ( d ) => {
			if ( d.geometry.type === 'LineString' || d.geometry.type === 'MultiLineString' ) {
				return geoGenerator( d );
			}
			return null;
		} )
		.attr( 'fill', 'none' )
		.attr( 'class', ( d ) => `${ d.properties.type } traffic-${ d.properties.id }` );

	const tooltipAttr = d3.select( element ).attr( 'data-traffic-tooltip' );

	if ( tooltipAttr && tooltipAttr === 'on' ) {
		addTraficTooltipListener( element );
	}
}
