/* eslint-disable no-console */
import * as d3 from 'd3';

export function drawTrees( geojson, element ) {
	const projection = element.projection;

	d3
		.select( element )
		.select( 'svg' )
		.select( 'g.wr-trees' )
		.attr( 'fill', 'green' )
		.selectAll( 'circle' )
		.data( geojson.features )
		.enter()
		.append( 'circle' )
		.attr( 'r', 2 )
		.attr( 'cx', ( d ) => projection( d.geometry.coordinates )[ 0 ].toFixed( 1 ) )
		.attr( 'cy', ( d ) => projection( d.geometry.coordinates )[ 1 ].toFixed( 1 ) )
		.attr( 'data-tree-id', ( d ) => `${ d.properties.tree_id }` );
}
