/* eslint-disable no-console */
// Set to save the already imported URLs
import * as d3 from 'd3';

const importedUrls = new Set();
const hiddenSvgId = 'hidden-svg';
let hiddenSvg = d3.select( `#${ hiddenSvgId }` );

// Create invisible SVG and add it to the document
export async function createHiddenSvg( ) {
	if ( ! hiddenSvg.empty() ) {
		return;
	}

	hiddenSvg = d3.select( 'body' )
		.append( 'svg' )
		.attr( 'id', hiddenSvgId )
		.style( 'position', 'absolute' )
		.style( 'width', '0' )
		.style( 'height', '0' )
		.style( 'overflow', 'hidden' );
}

function importStyles( importedSVG ) {
	const importedStyles = importedSVG.querySelectorAll( 'style' );

	if ( importedStyles.length > 0 ) {
		importedStyles.forEach( ( style ) => {
			document.head.appendChild( style.cloneNode( true ) );
		} );
	}
}

export async function importDefs( svgURL ) {
	await createHiddenSvg();

	// Check whether the URL has already been imported
	if ( importedUrls.has( svgURL ) ) {
		console.log( 'The SVG file has already been imported' );
		return;
	}

	try {
		const importedSVG = await d3.xml( svgURL );
		const importedDefs = importedSVG.querySelector( 'defs' );

		if ( importedDefs ) {
			// Add the nodes from the imported defs element
			Array.from( importedDefs.childNodes ).forEach( ( node ) => {
				hiddenSvg.node().appendChild( node.cloneNode( true ) );
			} );

			// Mark URL as imported
			importedUrls.add( svgURL );
		} else {
			console.error( 'The <defs> element was not found in the imported SVG file.' );
		}

		// Import styles
		importStyles( importedSVG );
	} catch ( error ) {
		console.error( `Error loading SVG file: ${ error.message }` );
	}
}
