/* eslint-disable no-console */
import * as d3 from 'd3';

export function drawWaveDecoration( element ) {
	const svg = d3.select( element ).select( 'svg' );

	// Wähle den Lake-Pfad
	const lake = svg.select( 'g.wr-inventory g.inventory-5 path' );

	// Überprüfe, ob der Lake-Pfad existiert
	if ( lake.empty() ) {
		console.error( 'Der angegebene Lake-Pfad existiert nicht.' );
		return;
	}

	// Erhalte das d-Attribut des Lake-Pfades
	const lakePathData = lake.attr( 'd' );

	// Erstelle ein clipPath-Element
	const clipPathId = 'lakeClip';
	svg.append( 'defs' )
		.append( 'clipPath' )
		.attr( 'id', clipPathId )
		.append( 'path' )
		.attr( 'd', lakePathData ); // Setze das d-Attribut für das clipPath

	// Erstelle die Gruppe für die Welle
	const waveGroup = svg.select( 'g.main' )
		.insert( 'g', 'g.wr-traffic' )
		.attr( 'class', 'wave' )
		.attr( 'clip-path', `url(#${ clipPathId })` );
		//.attr( 'transform', 'translate(0, -100)' ); // Wende den Clip-Pfad an

	// Füge ein Rechteck als roten Hintergrund hinzu
	waveGroup.append( 'rect' )
		.attr( 'x', 0 )
		.attr( 'y', -100 )
		.attr( 'width', '100%' )
		.attr( 'height', '100%' )
		.attr( 'fill', 'none' );

	// Füge das Symbol in die Gruppe ein
	waveGroup.append( 'use' )
		.attr( 'xlink:href', '#stk-lake' )
		.attr( 'x', 0 )
		.attr( 'y', -100 )
		.attr( 'transform', 'scale(1.25) translate(150, 30)' );
}
