/* eslint-disable no-console */
const $ = window.jQuery;

let resizeTimeout;

export const updateMapClass = () => {
	// console.log( 'updateMapClass' );
	$( '.waldruh-map' ).each( function() {
		// console.log( 'map found' );
		const width = $( this ).width();
		$( this ).removeClass( 'size-s size-m size-l' );

		if ( width < 320 ) {
			$( '#wrg-tooltip' ).css( 'font-size', '10px' );
		} else if ( width < 500 ) {
			$( '#wrg-tooltip' ).css( 'font-size', '12px' );
		}
	} );
};

const handleResize = () => {
	clearTimeout( resizeTimeout );
	resizeTimeout = setTimeout( updateMapClass, 100 );
};

export function addResizeHandler() {
	$( window ).on( 'resize', handleResize );
}
