/* eslint-disable no-console */
// tooltip.js
import * as d3 from 'd3';

const $ = window.jQuery;

export function createTooltip( element ) {
	let tooltip = element.querySelector( '.wrg-tooltip' );
	if ( ! tooltip ) {
		tooltip = document.createElement( 'div' );
		tooltip.classList.add( 'wrg-tooltip' );
		tooltip.style.position = 'absolute';
		tooltip.style.opacity = 0; // Start with tooltip hidden
		element.appendChild( tooltip );
	}
	return tooltip;
}

export function addTooltipListener( element ) {
	d3.select( element ).select( 'svg' ).selectAll( 'g[data-tooltip]' )
		.on( 'mouseover', function() {
			console.log( 'mouse over sym' );
			/* Complicated workaround due to a Firefox bug.

			Here, the object determined with .getBoundingClientRect() contains correct
			values for left and top, but incorrect values for width and height.

			We therefore calculate the actual width of the use-Tag using auxiliary methods. */

			const tooltipText = d3.select( this ).attr( 'data-tooltip' );
			console.log( tooltipText );
			const c = this.querySelector( 'circle' );
			console.log( 'c', c );
			// Get bounding rect of the parent element
			const cr = c.getBoundingClientRect();
			console.log( 'cr', cr );

			const pr = element.getBoundingClientRect(); // Koordinaten des Eltern-Elements
			console.log( 'element', element );
			console.log( 'pr', pr );

			const offsetX = cr.left - pr.left + ( cr.width / 2 );
			const offsetY = cr.top - pr.top + ( cr.height / 2 );
			console.log( 'offsetX', offsetX );
			console.log( 'offsetY', offsetY );

			const tooltip = d3.select( element ).select( '.wrg-tooltip' );

			const allDirections = [ 'north', 'north-east', 'east', 'south-east', 'south', 'south-west', 'west', 'north-west' ];

			allDirections.forEach( ( dir ) => {
				tooltip.classed( dir, false );
			} );

			console.log( 'tooltip', tooltip.attr( 'class' ) );

			tooltip
				.style( 'left', `${ offsetX }px` )
				.style( 'top', `${ offsetY }px` )
				.style( 'opacity', 1 )
				.text( tooltipText );
		} )
		.on( 'mouseout', function() {
			d3.select( element ).select( '.wrg-tooltip' )
				.style( 'opacity', 0 );
		} );
}

export function addMapMouseLeaveListerner() {
	$( document ).on( 'mouseleave', '.waldruh-map svg', function() {
		console.log( 'gn8!' );
		$( '.wrg-tooltip' ).css( 'opacity', 0 );
	} );
}
