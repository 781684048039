'use strict';

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import * as d3 from 'd3';
import * as turf from '@turf/turf';
import { config } from './config';
import { calculateFeatureCenterAndDistance } from './polylabelHelper';

export function drawSections( geojson, element ) {
	const projection = element.projection;
	geojson = turf.rewind( geojson, { reverse: true } );

	const geoGenerator = d3.geoPath().projection( projection );

	const secG = d3
		.select( element )
		.select( 'svg' )
		.select( 'g.wr-sections', true );

	const secGs = secG
		.selectAll( 'g' )
		.data( geojson.features )
		.join( 'g' )
		.attr( 'class', ( d ) => `sec-${ d.properties.sec_name }` );

	secGs.append( 'path' ).attr( 'd', geoGenerator );

	function addTextWithSubscript( textElement, text ) {
		text = String( text );

		const match = text.match( /^([A-Za-z])(\d)$/ );

		if ( match ) {
			textElement.append( 'tspan' ).text( match[ 1 ] );
			textElement.append( 'tspan' )
				.attr( 'baseline-shift', '-0.25em' )
				.attr( 'font-size', '0.75em' )
				.attr( 'dx', '0.05em' )
				.text( match[ 2 ] );
		} else {
			textElement.text( text );
		}
	}

	let minimalRadius = null;

	secGs.each( function( d ) {
		const result = calculateFeatureCenterAndDistance( d, element.projection );

		const center = result.center;
		const distance = result.distance;
		//console.log( 'center:', center );
		//console.log( 'distance:', distance );

		if ( distance === null ) {
			//console.error( 'Distance calculation failed.' );
			return;
		}

		//console.log( 'Distance:', distance );
		if ( ! minimalRadius || distance < minimalRadius ) {
			minimalRadius = distance;
		}
		//console.log( 'Minimal Radius:', minimalRadius );

		//console.log( 'Center:', center );

		if ( center ) {
			const textElement = d3.select( this )
				.append( 'text' )
				.attr( 'x', center[ 0 ] )
				.attr( 'y', center[ 1 ] )
				.attr( 'dy', '0.35em' );

			addTextWithSubscript( textElement, d.properties.sec_name );
		}
	} );

	//console.log( 'Minimal Radius:', minimalRadius );

	const factor = +config[ element.sok ].fontFactor || 1.5;
	const fontSize = factor * minimalRadius;
	//console.log( 'Font Size:', fontSize );

	secG.style( 'font-size', `${ fontSize }px` );
}
