/* eslint-disable no-console */
import * as d3 from 'd3';
import { getCoordsPromise } from './ajax-map';
const $ = window.jQuery;

function createGroup( element, idPrefix, coords, direction, isActive, useType, name, postId, symId = null, treeId = null, parentSelector = 'g.wr-links' ) {
	const linkG = d3.select( element ).select( 'svg' ).select( parentSelector );

	const groupId = treeId ? `${ idPrefix }-${ treeId }` : `${ idPrefix }-${ postId }`;
	const groupG = linkG
		.append( 'g' )
		.attr( 'id', groupId );

	const projection = element.projection;
	const projectedCoords = projection( coords );
	const x = projectedCoords[ 0 ];
	const y = projectedCoords[ 1 ];

	groupG.attr( 'transform', `translate(${ x }, ${ y })` );

	const state = isActive ? 'activated' : 'deactivated';

	// add invisible helper circle:
	groupG
		.append( 'circle' )
		.attr( 'r', 1 )
		.attr( 'opacity', '0' );

	const link = groupG.append( 'a' );

	// backend
	if ( postId && $( 'body' ).hasClass( 'wp-admin' ) ) {
		link.attr( 'href', `/wp-admin/post.php?post=${ postId }&action=edit` );
	}

	// frontend panorama
	if ( element.getAttribute( 'link-to' ) === 'scene' ) {
		link.attr( 'href', 'javascript:void(0)' )
			.attr( 'data-scene-id', groupId )
			.attr( 'class', 'scene' );
	}

	link.append( 'use' )
		.attr( 'href', `#${ symId || direction }` )
		.attr( 'width', 40 )
		.attr( 'height', 40 )
		.attr( 'data-name', name )
		.attr( 'data-direction', direction )
		.classed( direction, true )
		.classed( state, true )
		.classed( useType, true );

	const tooltip = element.querySelector( '.wrg-tooltip' );

	// Debounce-Funktion
	function debounce( func, wait ) {
		let timeout;
		return function( ...args ) {
			clearTimeout( timeout );
			timeout = setTimeout( () => func.apply( this, args ), wait );
		};
	}

	// Benannte Funktion zur Positionierung des Tooltips
	function positionTooltip() {
		const hovC = d3.select( this ).select( 'circle' );
		const hovUseTag = d3.select( this ).select( 'use' );

		if ( ! hovC.empty() && ! hovUseTag.empty() ) {
			const r = hovC.node().getBoundingClientRect();
			const parentRect = element.getBoundingClientRect(); // Koordinaten des Eltern-Elements

			const offsetX = r.left - parentRect.left + ( r.width / 2 );
			const offsetY = r.top - parentRect.top + ( r.height / 2 );

			d3.select( tooltip )
				.style( 'left', `${ offsetX }px` )
				.style( 'top', `${ offsetY }px` )
				.style( 'opacity', 1 )
				.html( hovUseTag.attr( 'data-name' ) )
				.attr( 'class', `wrg-tooltip ${ hovUseTag.attr( 'data-direction' ) }` ); // Update class
		}
	}

	// Debounced Version der Positionierungsfunktion
	const debouncedPositionTooltip = debounce( positionTooltip, 10 );

	// Funktion zum Anzeigen des Tooltips
	function showTooltip() {
		console.log( 'hover ' + d3.select( this ).attr( 'id' ) );

		// Bringt das aktuelle Element in den Vordergrund
		d3.select( this ).raise();

		// Positioniert den Tooltip nach einer Verzögerung
		debouncedPositionTooltip.call( this );
	}

	// Funktion zum Verstecken des Tooltips
	function hideTooltip() {
		d3.select( 'g.curG' ).raise();
		d3.select( tooltip ).style( 'opacity', 0 );
	}

	// Scroll-Event-Listener hinzufügen
	d3.select( '#info-box .box-main' ).on( 'scroll', function() {
		hideTooltip();
	} );

	groupG
		.on( 'mouseover', showTooltip )
		.on( 'mouseout', hideTooltip )
		.on( 'touchstart', function() {
			showTooltip.call( this );
			// Prevent the default behavior to avoid triggering mouse events
			d3.event.preventDefault();
		} )
		.on( 'touchend', function() {
			hideTooltip.call( this );
			// Prevent the default behavior to avoid triggering mouse events
			d3.event.preventDefault();
		} );

	return groupG;
}

export function addTreeGroup( element, treeId, coords, direction, isActive, useType, name, postId, parentSelector ) {
	createGroup( element, 'rb', coords, direction, isActive, useType, name, postId, null, treeId, parentSelector );
}

export function addPoiGroup( element, title, coords, direction, symId, isActive, postId, parentSelector ) {
	createGroup( element, 'poi', coords, direction, isActive, 'poi', title, postId, symId, null, parentSelector );
}

export async function createPanoLinks( element ) {
	if ( ! window.wrpObject ) {
		console.log( 'Waldruh Panoarama Plugin is not installed' );
		return;
	}

	const dataTreePanosVal = d3.select( element ).attr( 'data-tree-panos' );
	const dataPoiPanosVal = d3.select( element ).attr( 'data-poi-panos' );

	if ( dataTreePanosVal && [ 'all', 'active-only' ].includes( dataTreePanosVal ) ) {
		let data = await getCoordsPromise( 'tree' );

		if ( dataTreePanosVal === 'active-only' ) {
			data = data.filter( ( item ) => item.active );
		}

		if ( data.length ) {
			console.log( 'tree-panos-data', data );

			data.forEach( ( item ) => {
				addTreeGroup( element, item.treeId, item.coords, item.symDirection, item.active, item.useType, item.name, item.postId );
			} );
		}
	}

	if ( dataPoiPanosVal && [ 'all', 'active-only' ].includes( dataPoiPanosVal ) ) {
		let data = await getCoordsPromise( 'poi' );

		if ( dataPoiPanosVal === 'active-only' ) {
			data = data.filter( ( item ) => item.active );
		}

		if ( data.length ) {
			console.log( 'poi-panos-data', data );

			data.forEach( ( item ) => {
				addPoiGroup( element, item.name, item.coords, item.symDirection, item.symId, item.active, item.postId );
			} );
		}
	}
}
